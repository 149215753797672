import * as React from "react"

const BottomBanner = ({keyword}) => {
    return (
        <div className="bottom__banner">
            <div className="wrapper">
                <h2 className="bottom__banner-title">
                    Let’s write your {keyword ?? 'essay'} for you now!
                </h2>
                <a className="button button-border__main" href={process.env.SHOP_API + '/order/new'}>place an order</a>
            </div>
        </div>
    )
}

export default BottomBanner
