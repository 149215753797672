import React from "react";

//COMPONENTS
import Layout from "@components/layout"
import Seo from "@components/Seo.js"
import Breadcrumbs from "@components/Breadcrumbs"
import AboutImg from "@images/about_img.svg"
import WhoWeAre from "@components/aboutpage/WhoWeAre";
import WhyOrderFromUs from "@components/aboutpage/WhyOrderFromUs";
import TheServicesSlider from "@components/aboutpage/TheServicesSlider";
import WhyUs from "@components/mainpage/WhyUs";
import BottomBanner from "@components/BottomBanner";
import Sprite from "@images/sprites/sprite.svg"

const ServicesPage = () => {
    const steps= [
        {
            name: 'About us',
            path: '/info/services'
        }
    ]
    const metaInfo = {
        title: 'Our Services | Genuinewriting.com',
        description: 'Genuinewriting.com is an on-line writing service that is open 24/7. We write custom essays, term papers, research papers, college papers, dissertations and other custom written services for professional and academic use. We are a custom writing and ghost writing company with over 10 years of academic writing experience.' 
    }

    return (
        <Layout>
            <Seo {...metaInfo} />
            <div className="services-intro">
                <div className="wrapper row">
                    <div className="services-intro--content">
                        <Breadcrumbs steps={steps}/>
                        <h1 className="page-intro__title">About us</h1>
                        <p className="services-intro--desc">
                            Genuinewriting.com is an on-line writing service that is open 24/7. 
                            We write custom essays, term papers, research papers, college papers, dissertations and other custom written services for professional and academic use. We are a custom writing and ghost writing company 
                            with over 10 years of academic writing experience.
                        </p>
                    </div>
                    <div className="services-intro--image">
                        <img src={AboutImg} alt="About us" width="401" height="389" />
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <WhoWeAre />
                <WhyOrderFromUs />
            </div>
            <TheServicesSlider />
            <WhyUs sprite={Sprite} />
            <BottomBanner keyword='custom essay' />
        </Layout>
    )
}

export default ServicesPage