// extracted by mini-css-extract-plugin
export var servicesSliderCenter = "_services-slider-module--services-slider-center--+i34g";
export var servicesSliderTitle = "_services-slider-module--services-slider-title--shNqC";
export var servicesSlider = "_services-slider-module--services-slider--zNI6W";
export var buttonWrapper = "_services-slider-module--button-wrapper--nNJCX";
export var servicesCarousel = "_services-slider-module--services-carousel--9iYN2";
export var servicesCarouselSlider = "_services-slider-module--services-carousel-slider--UmhHy";
export var servicesCarouselItem = "_services-slider-module--services-carousel-item--p96sP";
export var servicesCarousel_itemTitle = "_services-slider-module--services-carousel_item-title--U4jxZ";
export var servicesCarouselItemContent = "_services-slider-module--services-carousel-item-content--yZTSi";
export var servicesCarouselItemTitle = "_services-slider-module--services-carousel-item-title--H-MDV";
export var servicesCarouselItemImg = "_services-slider-module--services-carousel-item-img--56RvN";
export var servicesCarouselItemDescr = "_services-slider-module--services-carousel-item-descr--CbUh9";